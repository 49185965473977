

















import { Component, Vue } from 'vue-property-decorator';
import AsyncButton from '@/components/shared/AsyncButton.vue';
import { Authenticator, NavRouter } from '@/services';
import { lazyInject } from '@/ioc/inversify.config';
import { TYPES } from '@/ioc/types';
import { SHENGCI_MODULES, VUE_LIFECYCLE_EVENT } from '@/enums';

@Component({
  components: {
    AsyncButton,
  }
})
export default class LoginView extends Vue
{  
  @lazyInject(TYPES.AUTHENTICATOR_INSTANCE)
  private authenticator!: Authenticator;

  @lazyInject(TYPES.NAVROUTER_INSTANCE)
  private nav_router!: NavRouter;

  private logout_message: string = 'Thank you, come again!';
  private logout_button_state: boolean = true;
  public readonly module_type: SHENGCI_MODULES = SHENGCI_MODULES.LOGOUT;

  mounted(): void {
    this.nav_router.pushModuleLifecycleEvent({module: this.module_type, event: VUE_LIFECYCLE_EVENT.MOUNTED});
  }

  logout(change_message: boolean = false): void {
    this.logout_button_state = true;
    if(change_message) {
      this.logout_message = 'Logging out...';
    }
    this.authenticator.tryLogout().catch(()=>{
      this.logout_button_state = false;
      this.logout_message = 'Could not log you out. Are you offline?';
    });
  }

  created(): void {
    this.logout();
  }
}
